import React from "react";

import king_of_glory from "images/games/kingofglory.png";
import peace from "images/games/peace elite.png";
import happy_fun from "images/games/happy fun.png";
import happy_landlord from "images/games/happy landlord.png";
import mini_world from "images/games/mini world.png";
import line_fire from "images/games/cross the line of fire.png";
import snake_fight from "images/games/snake fight.png";
import qq_speed from "images/games/qq speed car.png";
import parkour_tom from "images/games/parkour tom cat.png";
import minecraft from "images/games/minecraft.png";
import ballfight from "images/games/Ball Fight.png";
import jj_landlord from "images/games/jj landlord.png";
import vital_knight from "images/games/vital_knight.png";
import subway_parkour from "images/games/subway parkour.png";
import sausage_party from "images/games/sausage_party.png";
import piano_block from "images/games/piano_block.png";
import archer_battle from "images/games/archer_battle.png";
import pp_play from "images/games/pp play.png";
import internet_coffee from "images/games/internet_coffee.png";
import animal_farm from "images/games/animal_farm.png";
import clash_of_clans from "images/games/clash.jpeg";
import anipop from "images/games/anipop.png";
import lol from "images/games/lol.png";
import jellipop_match from "images/games/jellipop_match.jpeg";
import sky from "images/games/sky_children.webp";
import chan_chan from "images/games/chan_chan.png";

const getDeveloperLink = (url, title) => (
  <>
    <span>Publisher</span>:{" "}
    <span>
      <a rel="noopener noreferrer" target="_blank" href={url}>
        {title}
      </a>
    </span>
  </>
);

const img_mapping = {
  king_of_glory: king_of_glory,
  peace: peace,
  happy_fun: happy_fun,
  happy_landlord: happy_landlord,
  mini_world: mini_world,
  line_fire: line_fire,
  snake_fight: snake_fight,
  qq_speed: qq_speed,
  parkour_tom: parkour_tom,
  minecraft: minecraft,
  ballfight: ballfight,
  jj_landlord: jj_landlord,
  vital_knight: vital_knight,
  subway_parkour: subway_parkour,
  sausage_party: sausage_party,
  piano_block: piano_block,
  archer_battle: archer_battle,
  pp_play: pp_play,
  internet_coffee: internet_coffee,
  animal_farm: animal_farm,
  clash_of_clans: clash_of_clans,
  anipop: anipop,
  lol: lol,
  jellipop_match: jellipop_match,
};

let urls_mapping = {
  "Shenzhen Tencent Computer System Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=1",
  "Element Technology (Beijing) Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=3635",
  "Shenzhen Mini Play Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=50519",
  "Wuhan Weipai Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=2549",
  "Huaian Huaiyangxin Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=61511",
  "Shanghai Wangzhiyiwu World Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=51738",
  PapaBox:
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=61529",
  "Beijing Cheetah Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=29821",
  "Sports World (Beijing) Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=1362",
  "Giant Mobile Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=5454",
  "Guangzhou 4399 Information Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=5267",
  "Topview Information System Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=103",
  "Tianjin Duoku Zitong Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=50520",
  "Shenzhen Dreamland Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=10542",
  "Xiamen really interesting Information Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=3778",
};

const raw_data = [{'details': ['Chinese Name: 王者荣耀',
'Game Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)',
'Publihser: Shenzhen Tencent Computer System Co.,Ltd.'],
'image': king_of_glory,
'mau': '154,366,300',
'mau_change': '-1.61%',
'text': 'King of Glory'},
{'details': ['Chinese Name: 和平精英',
'Game Type: Shooting/FPS (First Person Shooter)',
'Publihser: Shenzhen Tencent Computer System Co., Ltd.'],
'image': peace,
'mau': '72,527,100',
'mau_change': '-4.3%',
'text': 'Peace Elite'},
{'details': ['Chinese Name: 开心消消乐',
'Game Type: Casual/Elimination',
'Publisher: Happy Elements Technology (Beijing) Co., Ltd.'],
'image': anipop,
'mau': '67,664,600',
'mau_change': '-2.89%',
'text': 'Anipop'},
{'details': ['Chinese Name: 我的世界（网易）',
'Game Type: Simulation/Sandbox',
'Publisher: Shanghai NetEase MineWorld Network Technology Co.,Ltd'],
'image': minecraft,
'mau': '32,003,000',
'mau_change': '-7.41%',
'text': 'Minecraft (Netease)'},
{'details': ['Chinese Name: 迷你世界',
'Game Type: Simulation/3D Sandbox',
'Publisher: Shenzhen Miniwan Technology Co.,Ltd'],
'image': mini_world,
'mau': '29,859,800',
'mau_change': '-3.96%',
'text': 'Mini World'},
{'details': ['Chinese Name: 欢乐斗地主（腾讯）',
'Game Type: TAB (Table Game)/Chess and Cards',
'Publisher: Shenzhen Tencent Computer System Co., Ltd.'],
'image': happy_landlord,
'mau': '25,549,000',
'mau_change': '-5.86%',
'text': 'Happy Poker (Tencent)'},
{'details': ['Chinese Name: 英雄联盟手游',
'Game Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)',
'Publisher: Shenzhen Tencent Computer System Co., Ltd.'],
'image': lol,
'mau': '25,321,700',
'mau_change': '-3.12%',
'text': 'League of Legends'},
{'details': ['Chinese Name: 光 · 遇',
'Game Type: Casual / Adventure',
'Publisher: Hangzhou NetEase Thunder Fire Technology Co., Ltd.'],
'image': sky,
'mau': '19,569,200',
'mau_change': '+5.65%',
'text': 'Sky: Children of the Light'},
{'details': ['Chinese Name: 金铲铲之战',
'Game Type: Strategy / SLG (Simulated Life Game)',
'Publisher: Shenzhen Tencent Computer System Co., Ltd.'],
'image': chan_chan,
'mau': '17,455,700',
'mau_change': '+3.53%',
'text': 'JinChanChan'},
{'details': ['Chinese Name: 球球大作战',
'Game Type: Casual/Elimination',
'Publisher: Giant Network Technology Co., Ltd.'],
'image': ballfight,
'mau': '16,277,600',
'mau_change': '-5.66%',
'text': 'Battle of Balls'},
{'details': ['Chinese Name: 元气骑士',
'Game Type: Action/Adventure',
'Publisher: Guangzhou 4399 Information Technology Co., Ltd.'],
'image': vital_knight,
'mau': '15,930,300',
'mau_change': '-0.45%',
'text': 'Vital Knight'},
{'details': ['Chinese Name: 贪吃蛇大作战',
'Game Type: Casual/Control',
'Publisher: Wuhan Weipai Network Technology Co., Ltd.'],
'image': snake_fight,
'mau': '15,688,300',
'mau_change': '-4.83%',
'text': 'Snake Fight'},
{'details': ['Chinese Name: 地铁跑酷（世界环游）',
'Game Type: Racing/Parkour',
'Publisher: Shenzhen Dreamland Technology Co., Ltd.'],
'image': subway_parkour,
'mau': '15,684,800',
'mau_change': '-1.3%',
'text': 'Subway Parkour (World Tour)'},
{'details': ['Chinese Name: 香肠派对',
'Game Type: FPS (First Person Shooter)',
'Publisher: XD Inc.'],
'image': sausage_party,
'mau': '14,664,500',
'mau_change': '-1.83%',
'text': 'Sausage Party'},
{'details': ['Chinese Name: 穿越火线',
'Game Type: Shooting/FPS (First Person Shooter)',
'Publisher: Shenzhen Tencent Computer System Co., Ltd.'],
'image': line_fire,
'mau': '14,339,500',
'mau_change': '-6.61%',
'text': 'Cross Fire'},
{'details': ['Chinese Name: JJ斗地主',
'Game Type: TAB (table game)/Chess and Cards',
'Publisher: JJWorld (Beijing) Network Technology Co., Ltd.'],
'image': jj_landlord,
'mau': '14,177,800',
'mau_change': '-3.26%',
'text': 'JJ Landlord'},
{'details': ['Chinese Name: 植物大战僵尸2',
'Game Type: SLG (Simulated Life Game)/Tower Defense',
'Publisher: Topview Information System Co., Ltd.'],
'image': pp_play,
'mau': '13,049,500',
'mau_change': '-17.74%',
'text': 'Plants vs Zombies 2'},
{'details': ['Chinese Name: 钢琴块2',
'Game Type: Casual/Musical Rhythm',
'Publisher: Beijing Cheetah Network Technology Co., Ltd.'],
'image': piano_block,
'mau': '12,821,500',
'mau_change': '-10.83%',
'text': 'Piano Tiles 2'},
{'details': ['Chinese Name: QQ飞车',
'Game Type: Racing/Car Racing',
'Publisher: Shenzhen Tencent Computer System Co., Ltd.'],
'image': qq_speed,
'mau': '12,522,600',
'mau_change': '+1.62%',
'text': 'QQ Speed Car'},
{'details': ['Chinese Name: 部落冲突',
'Game Type: SLG (Simulated Life Game)/Strategy',
'Publisher: Beijing Kunlun Lexiang Network Technology Co., Ltd.'],
'image': clash_of_clans,
'mau': '12,090,400',
'mau_change': '-4.92%',
'text': 'Clash of Clans'}];

let processed_data = raw_data.map((it) => {
  return {
    ...it,
    details: [...it.details, `Publisher: ${it.publisher}`],
    website: null,
  };
});

export const GAMES = raw_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));

// Keeping this as a record of the old implememtation
// export const GAMES = [
//   {
//     image: king_of_glory,
//     text: "King of Glory",
//     mau: "153,553,000",
//     details: [
//       `Chinese Name: 王者荣耀`,
//       `Game Type: MOBA (Mutiplayer Online Battle Arena)`,
//       getDeveloperLink(
//         "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=1",
//         "Shenzhen Tencent Computer System Co., Ltd."
//       ),
//     ],
//     website: null,
//   },
// ].map((it, index) => ({ ...it, index: index + 1 }));